<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 22.267 22.267">
    <path id="Icon_awesome-whatsapp" d="M18.932,5.486A11.038,11.038,0,0,0,1.566,18.8L0,24.517l5.85-1.536a11,11,0,0,0,5.274,1.342h0A11.14,11.14,0,0,0,22.267,13.289a11.078,11.078,0,0,0-3.335-7.8Zm-7.8,16.979a9.155,9.155,0,0,1-4.672-1.277l-.333-.2-3.469.91.924-3.385-.219-.348A9.189,9.189,0,1,1,20.4,13.289a9.274,9.274,0,0,1-9.275,9.175Zm5.03-6.869c-.273-.139-1.63-.805-1.884-.895s-.437-.139-.621.139-.711.895-.875,1.084-.323.209-.6.07a7.5,7.5,0,0,1-3.753-3.28c-.283-.487.283-.452.81-1.506a.511.511,0,0,0-.025-.482c-.07-.139-.621-1.5-.85-2.048s-.452-.462-.621-.472-.343-.01-.527-.01a1.021,1.021,0,0,0-.736.343,3.1,3.1,0,0,0-.964,2.3A5.4,5.4,0,0,0,6.64,13.692a12.32,12.32,0,0,0,4.712,4.165,5.4,5.4,0,0,0,3.31.691,2.824,2.824,0,0,0,1.859-1.312,2.306,2.306,0,0,0,.159-1.312C16.616,15.8,16.432,15.73,16.159,15.6Z" transform="translate(0 -2.25)" fill="#666"/>
  </svg>
</template>

<script>
export default {
  name: "ZWhatsappSvg"
}
</script>

<style scoped>

</style>
