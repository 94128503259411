<template>
  <section class="sotial-networks">
    <a
      style="display: flex;justify-content: center;"
      v-if="homeData"
      :href="
        homeData.settings.customer_setting
          ? homeData.settings.customer_setting.instagram
          : ''
      "
      class="mx-2"
    >
      <img
        src="../../../../assets/images/insta.jpg"
        alt="insta"
        class="instaFooter"
      />
    </a>
  </section>
</template>

<script>
import ZWhatsappSvg from "./ZWhatsappSvg";
export default {
  name: "SocialNetworks",
  components: { ZWhatsappSvg },
  computed: {
    homeData() {
      return this.$store.getters["front/getHomeData"];
    },
    loading() {
      return this.$store.getters["front/getHomeDataStatus"];
    },
  },
};
</script>

<style scoped>
img.instaFooter {
  width: 320px;
  border-radius: 5px;
}
@media (max-width: 480px) {
  img.instaFooter {
    width: 95%;
    border-radius: 5px;
  }
}
</style>
