<template>
  <div class="in-login-register">
    <transition name="fade">
      <form
          @submit.prevent="login"
        v-if="loginSection"
        id="loginSection"
        :class="{ 'col-md-9': showFullForm, marginAuto: showFullForm }"
        class="modal-body px-sm-5 px-3 pt-sm-4 pb-sm-5 pb-3"
      >
        <div class="text-center d-flex justify-content-center">
          <span class="fontsize15 text-color-444">{{ telephone }}</span>
          <button @click.prevent="editLoginTelephone()" type="button" class="mr-4 bg-none">
            <svg
              width="15"
              height="15"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M9.3764 20.0279L18.1628 8.66544C18.6403 8.0527 18.8101 7.3443 18.6509 6.62299C18.513 5.96726 18.1097 5.34377 17.5049 4.87078L16.0299 3.69906C14.7459 2.67784 13.1541 2.78534 12.2415 3.95706L11.2546 5.23735C11.1273 5.39752 11.1591 5.63401 11.3183 5.76301C11.3183 5.76301 13.812 7.76246 13.8651 7.80546C14.0349 7.96671 14.1622 8.1817 14.1941 8.43969C14.2471 8.94493 13.8969 9.41792 13.377 9.48242C13.1329 9.51467 12.8994 9.43942 12.7297 9.29967L10.1086 7.21422C9.98126 7.11855 9.79025 7.13898 9.68413 7.26797L3.45514 15.3303C3.0519 15.8355 2.91395 16.4912 3.0519 17.1255L3.84777 20.5761C3.89021 20.7589 4.04939 20.8879 4.24039 20.8879L7.74222 20.8449C8.37891 20.8341 8.97316 20.5439 9.3764 20.0279ZM14.2797 18.9533H19.9898C20.5469 18.9533 21 19.4123 21 19.9766C21 20.5421 20.5469 21 19.9898 21H14.2797C13.7226 21 13.2695 20.5421 13.2695 19.9766C13.2695 19.4123 13.7226 18.9533 14.2797 18.9533Z"
                fill="#28a745"
              />
            </svg>
            <span class="fontsize13 text-success mr-2">ویرایش شماره</span>
          </button>
        </div>

        <div :class="{ paswordInputFullForm: showFullForm }">
          <div class="d-flex align-items-center mt-3">
            <!-- با کلیک روی چشم پسورد نمایش داده میشود -->
            <i
              v-if="showPass"
              class="fa fa-eye"
              @click="showPass = !showPass"
              style="cursor: pointer"
            ></i>
            <i
              v-else
              class="fa fa-eye-slash"
              @click="showPass = !showPass"
              style="cursor: pointer"
            ></i>
            <input
              v-model="loginPassword"
              class="loginInputs"
              :type="isSafari() ? 'text' : 'password'"
              id="passWord"
              ref="passWord"
              :style="mediaQueries.mobileSize ? 'direction: ltr' :''"
              placeholder="لطفا رمز عبور خود را وارد کنید.."
            />
          </div>
          <hr id="hrInput" />
          <div>
            <input
              :class="{ disableLink: disabled1 }"
              type="submit"
              class="
                p-2
                mt-3
                fontsize-medium
                weight-bold
                bg-color-theme
                text-white
                maxWidth
                send
                btnLogin
              "
              value="ورود"
            />
          </div>
          <p
            id="loginErrorBox"
            class="d-flex justify-content-center text-danger"
          >
            {{ loginError }}
          </p>
        </div>
        <div id="otherUserOption" class="d-flex flex-sm-row flex-column">
          <p
            class="text-primary d-inline align-items-center" style="width: max-content"
            @click="forgetPassword()"
          >
            فراموشی رمز عبور
            <i class="fa fa-chevron-left mr-1"></i>
          </p>

          <p
            class="text-primary d-inline align-items-center" style="width: max-content"
            @click="loginWithToken()"
          >
            ورود با رمز یکبار مصرف
            <i class="fa fa-chevron-left mr-1"></i>
          </p>
        </div>
      </form>
    </transition>
    <transition name="fade">
      <div v-if="signUpSection" id="signUpSection">
        <transition name="fade">
          <div
            v-if="signUpSectionPart1"
            id="registerSection"
            class="d-flex justify-content-center my-3 px-2"
          >
            <div id="InsertMobile">
              <div class="d-flex justify-content-center my-3 px-2">
                <svg
                  class="flex-shrink-0"
                  width="16"
                  height="16"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M18.1666 14.3797C17.4949 14.2352 16.9635 14.5471 16.493 14.8195C16.0111 15.1001 15.0949 15.8434 14.5697 15.6532C11.8808 14.5461 9.35189 12.1926 8.25714 9.49291C8.0642 8.95651 8.804 8.03445 9.08259 7.54691C9.35292 7.07496 9.65833 6.53856 9.51904 5.86183C9.39316 5.2537 7.76497 3.18192 7.18923 2.61538C6.80952 2.24115 6.42053 2.03532 6.02123 2.00205C4.51995 1.9376 2.84327 3.94078 2.54921 4.42C1.8125 5.44186 1.81663 6.80157 2.56159 8.45026C4.35693 12.8787 11.1472 19.5618 15.5922 21.4246C16.4125 21.8082 17.1626 22.0005 17.8364 22.0005C18.4957 22.0005 19.0828 21.8165 19.5874 21.4517C19.9681 21.2323 22.0534 19.4724 21.9987 17.9308C21.9657 17.5378 21.7604 17.1449 21.391 16.7644C20.8286 16.1833 18.7702 14.5066 18.1666 14.3797Z"
                    fill="#a5a5a5"
                  />
                </svg>
                <p class="fontsize15 mr-2 text-center text-color-444">
                  به فروشگاه {{$root.siteTitle}} خوش آمدید
                </p>
                <!-- <p v-else class="fontsize12 mr-2 text-center">کد 5 رقمی ارسال شده به شماره تماس وارد شده را وارد کنید!</p>-->
              </div>
              <form
                  @submit.prevent="sendCodeToCustomer"
                action=""
                class="d-flex flex-column mx-sm-4 mx-0 fontsize-medium"
              >
                <input
                  id="mobileInput"
                  v-model="telephone"
                  type="tel"
                  maxlength="11"
                  class="p-3 mb-2 text-center"
                  placeholder="شماره موبایل خود را وارد کنید"
                />
                <input
                  :class="{ disableLink: disabled }"
                  type="submit"
                  class="
                    p-3
                    weight-bold
                    bg-color-theme
                    text-white
                    fontsize-medium
                    btnLogin
                  "
                  value="ورود"
                />
                <p class="text-danger numberWar" v-if="showAlert">
                  لطفا شماره همراه را به درستی وارد نمایید
                </p>
              </form>
            </div>
          </div>
        </transition>
        <transition name="fade">
          <div
            v-if="signUpSectionPart2"
            id="TokenSection"
            style="height: fit-content;"
            class="modal-body "
          >
            <div class="d-flex justify-content-center my-3 px-2">
              <svg
                class="flex-shrink-0"
                width="16"
                height="16"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M2.00012 11.9993C2.00012 6.4803 6.48012 1.9993 12.0001 1.9993C17.5301 1.9993 22.0001 6.4803 22.0001 11.9993C22.0001 17.5203 17.5301 21.9993 12.0001 21.9993C6.48012 21.9993 2.00012 17.5203 2.00012 11.9993ZM11.1201 8.2093C11.1201 7.7303 11.5201 7.3293 12.0001 7.3293C12.4801 7.3293 12.8701 7.7303 12.8701 8.2093V12.6293C12.8701 13.1103 12.4801 13.4993 12.0001 13.4993C11.5201 13.4993 11.1201 13.1103 11.1201 12.6293V8.2093ZM12.0101 16.6803C11.5201 16.6803 11.1301 16.2803 11.1301 15.8003C11.1301 15.3203 11.5201 14.9303 12.0001 14.9303C12.4901 14.9303 12.8801 15.3203 12.8801 15.8003C12.8801 16.2803 12.4901 16.6803 12.0101 16.6803Z"
                  fill="#a5a5a5"
                />
              </svg>
              <p class="fontsize-medium mr-sm-2 mr-1 text-center text-color-444">
                کد 5 رقمی ارسال شده به شماره همراه خود را وارد کنید!
              </p>
            </div>
            <div class="text-center">
              <span class="fontsize15 text-color-444">{{ telephone }}</span>
              <button type="button" @click.prevent="editTelephone()" class="mr-4 bg-none">
                <svg
                  width="15"
                  height="15"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M9.3764 20.0279L18.1628 8.66544C18.6403 8.0527 18.8101 7.3443 18.6509 6.62299C18.513 5.96726 18.1097 5.34377 17.5049 4.87078L16.0299 3.69906C14.7459 2.67784 13.1541 2.78534 12.2415 3.95706L11.2546 5.23735C11.1273 5.39752 11.1591 5.63401 11.3183 5.76301C11.3183 5.76301 13.812 7.76246 13.8651 7.80546C14.0349 7.96671 14.1622 8.1817 14.1941 8.43969C14.2471 8.94493 13.8969 9.41792 13.377 9.48242C13.1329 9.51467 12.8994 9.43942 12.7297 9.29967L10.1086 7.21422C9.98126 7.11855 9.79025 7.13898 9.68413 7.26797L3.45514 15.3303C3.0519 15.8355 2.91395 16.4912 3.0519 17.1255L3.84777 20.5761C3.89021 20.7589 4.04939 20.8879 4.24039 20.8879L7.74222 20.8449C8.37891 20.8341 8.97316 20.5439 9.3764 20.0279ZM14.2797 18.9533H19.9898C20.5469 18.9533 21 19.4123 21 19.9766C21 20.5421 20.5469 21 19.9898 21H14.2797C13.7226 21 13.2695 20.5421 13.2695 19.9766C13.2695 19.4123 13.7226 18.9533 14.2797 18.9533Z"
                    fill="#28a745"
                  />
                </svg>
                <span class="fontsize13 text-success mr-2">ویرایش شماره</span>
              </button>
            </div>
            <div
              :class="{ codeInputFullForm: showFullForm }"
              id="codeInput"
              class="input-login-code text-center mt-4"
            >
              <input
                v-model="codeInput[0]"
                class="fontsize-medium text-center border-radius10"
                id="codeInput1"
                ref="codeInput1"
                @keyup="changeInput"
                @click="selectInput"
                v-number
                :type="mediaQueries.mobileSize ? 'tel' : 'number'"
                maxlength="1"
              />
              <input
                @keyup="changeInput"
                v-model="codeInput[1]"
                class="fontsize-medium ml-sm-3 ml-2 text-center border-radius10"
                @click="selectInput"
                v-number
                id="codeInput2"
                :type="mediaQueries.mobileSize ? 'tel' : 'number'"
                maxlength="1"
              />
              <input
                @keyup="changeInput"
                class="fontsize-medium ml-sm-3 ml-2 text-center border-radius10 "
                v-model="codeInput[2]"
                v-number
                @click="selectInput"
                id="codeInput3"
                :type="mediaQueries.mobileSize ? 'tel' : 'number'"
                :maxlength="1"
              />
              <input
                @keyup="changeInput"
                class="fontsize-medium ml-sm-3 ml-2 text-center border-radius10"
                v-model="codeInput[3]"
                @click="selectInput"
                v-number
                id="codeInput4"
                :type="mediaQueries.mobileSize ? 'tel' : 'number'"
                maxlength="1"
              />
              <input
                @keyup="changeInput"
                class="fontsize-medium ml-sm-3 ml-2 text-center border-radius10"
                :type="mediaQueries.mobileSize ? 'tel' : 'number'"
                v-model="codeInput[4]"
                v-number
                @click="selectInput"
                id="codeInput5"
                maxlength="1"
              />
              <input
                v-if="!showFullForm"
                :class="{ disableLink: disabledVerify }"
                @click.prevent="verify()"
                type="submit"
                class="
                  p-3
                  mt-3
                  fontsize-medium
                  weight-bold
                  bg-color-theme
                  text-white
                  btnLogin
                
                "
                value="تایید کد"
              />
            </div>
            <input
              v-if="showFullForm"
              :class="{
                disableLink: disabledVerify,
                verifyFullFrom: showFullForm,
              }"
              @click.prevent="verify()"
              type="submit"
              class="
                p-3
                mt-3
                fontsize-medium
                weight-bold
                bg-color-theme
                text-white
              "
              value="تایید کد"
            />
            <div>
              <p style="margin: 2px" class="text-danger numberWar">
                {{ verfiyError }}
              </p>
            </div>
          </div>
        </transition>

        <transition name="fade">
          <form @submit.prevent="signUpCustomer"
            v-if="signUpSectionPart3"
            class="modal-body px-sm-5 px-3 pt-sm-4 pb-sm-5 pb-3"
            id="signUpForm"
          >
            <template v-if="!showFullForm">
              <p class="d-flex justify-content-center">
                یک رمزعبور برای حساب کاربری خود وارد کنید
              </p>
              <div>
                <i class="fa fa-key"></i>
                <input
                  class="loginInputs"
                  :type="isSafari() ? 'text' : 'password'"
                  v-model="password"
                  id="password"
                  placeholder="رمز عبور (حداقل 6 کاراکتر)"
                  :style="mediaQueries.mobileSize ? 'direction: ltr' :''"
                />
              </div>
            </template>
            <template v-else>
              <div id="registerForm">
                <div class="col-md-6">
                  <div class="p-3 d-flex fontsize14 flex-column weight-bold">
                    <label class="text-color-999" for="">نام:</label>
                    <input
                      class="
                        text-color-444
                        bg-color-eai
                        p-md-3 p-2
                      "
                      type="text"
                      ref="firstName"
                      :class="{ inputError: firstNameError }"
                      v-model="first_name"
                    />
                  </div>
                  <div class="p-3 d-flex fontsize14 flex-column weight-bold">
                    <label class="text-color-999" for="">نام خانوادگی:</label>
                    <input
                      class="
                        text-color-444
                        bg-color-eai
                        p-md-3 p-2
                      "
                      type="text"
                      ref="lastName"
                      :class="{ inputError: lastNameError }"
                      v-model="last_name"
                    />
                  </div>
                  <div class="p-3 d-flex fontsize14 flex-column weight-bold">
                    <label class="text-color-999" for="">ایمیل:</label>
                    <input
                      class="
                        text-color-444
                        bg-color-eai
                        p-md-3 p-2
                      "
                      type="email"
                      ref="email"
                      :class="{ inputError: emailError }"
                      v-model="email"
                    />
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="p-3 d-flex fontsize14 flex-column weight-bold">
                    <label class="text-color-999" for="">رمز عبور:</label>
                    <input
                      class="
                        text-color-444
                        bg-color-eai
                        p-md-3 p-2
                      "
                      :type="isSafari() ? 'text' : 'password'"
                      :class="{ inputError: passwordError }"
                      ref="password"
                      v-model="password"
                    />
                  </div>
                  <div class="p-3 d-flex fontsize14 flex-column weight-bold">
                    <label class="text-color-999" for="">تاریخ تولد:</label>
                    <date-picker
                      format="x"
                      ref="brithDay"
                      v-model="brithDay"
                      display-format="YYYY/MM/DD"
                      color="#121314"
                    />
                  </div>

                  <div
                    id="chooseGenderAndImageBox"
                    class="m-3 pb-3 d-flex fontsize14 flex-row weight-bold"
                  >
                    <span class="text-color-999 ml-4 mt-1">جنسیت:</span>
                  </div>
                  <div class="d-flex">
                    <treeselect
                      :clearable="false"
                      :options="genderOption"
                      v-model="gender"
                    />
                  </div>
                </div>
              </div>
            </template>
            <hr class="mt-0"/>
            <input
              :class="{
                disableLink: signUpDisabled,
                signUpFullForm: showFullForm,
              }"
              type="submit"
              class="
                p-3
                mt-3
                fontsize-small
                weight-bold
                bg-color-theme
                text-white
                maxWidth
                btnLogin
              "
              value="ثبت اطلاعات"
            />
            <p class="mt-2 d-flex justify-content-center text-danger">
              {{ SignUpError }}
            </p>
          </form>
        </transition>
      </div>
    </transition>
    <transition name="fade">
      <div
        v-if="forgetSection"
        id="forgetSection"
        :class="{ 'forget-Full-Form': showFullForm }"
        class="modal-body px-sm-5 px-3 pt-sm-4 pb-sm-5 pb-3"
      >
        <p class="d-flex justify-content-center">
          لطفا در ورود اطلاعات دقت فرمایید
        </p>
        <div>
          <i class="fa fa-key"></i>
          <input
              :style="mediaQueries.mobileSize ? 'direction: ltr' :''"
              class="loginInputs"
              :type="isSafari() ? 'text' : 'password'"
            v-model="newPassword"
            id="newPassword"
            placeholder="رمز عبور"
          />
        </div>
        <hr class="mt-0" />
        <div>
          <i class="fa fa-key"></i>
          <input
              :style="mediaQueries.mobileSize ? 'direction: ltr' :''"
              class="loginInputs"
              :type="isSafari() ? 'text' : 'password'"
            v-model="newRePassword"
            id="newRePassword"
            placeholder="تکرار رمز عبور"
            @keypress.enter="sendNewPassword()"
          />
        </div>
        <hr class="mt-0" />
        <input
          :class="{ disableLink: forgetDisabled }"
          @click.prevent="sendNewPassword()"
          type="submit"
          class="
            p-3
            mt-3
            fontsize-small
            weight-bold
            bg-color-theme
            text-white
            maxWidth
            btnLogin
          "
          value="ثبت اطلاعات"
        />
        <p class="d-flex justify-content-center text-danger">
          {{ forgetError }}
        </p>
      </div>
    </transition>
  </div>
</template>
<script>
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import { Treeselect } from "@riophae/vue-treeselect";
import cart from "@/parts/Front/components/cart/cart";
import $ from "jquery";
import {isSafari} from '@/libraries/functions'


export default {
  components: {
    Treeselect,
  },
  props: {
    showFullForm: { Boolean },
    value: { String },
    textModalHeader: { String },
  },
  inject: ["mediaQueries"],
  name: "LoginRegister",

  data() {
    return {
      genderError: false,
      passwordError: false,
      emailError: false,
      lastNameError: false,
      firstNameError: false,
      gender: "male",
      genderOption: [
        {
          id: "male",
          label: "مرد",
        },
        {
          id: "female",
          label: "زن",
        },
      ],
      brithDay: null,
      first_name: null,
      last_name: null,
      email: null,
      mobileModal: false,
      showPass: false,
      disabledVerify: false,
      loginError: "",
      loginPassword: null,
      mobile: null,
      newsletter: false,
      foreign_national: false,
      national_code: null,
      password: null,
      codeInput: [],
      textModal: this.textModalHeader,
      loginSection: false,
      TokenSection: false,
      signUpSection: true,
      signUpSectionPart1: true,
      signUpSectionPart2: false,
      signUpSectionPart3: false,
      forgetSection: false,
      telephone: null,
      verfiyError: "",
      disabled: false,
      disabled1: false,
      showAlert: false,
      tokenName: "",
      newRePassword: null,
      newPassword: null,
      forgetDisabled: false,
      forgetError: "",
      name: null,
      lastName: null,
      city: null,
      address: null,
      postalCode: null,
      signUpDisabled: false,
      SignUpError: "",
    };
  },
  methods: {
    isSafari,
    focusOnInput(e) {
      alert('ok')
      e.stopPropagation();
      e.target.select()
    },
    // برای بازگشت به فرم ورود شماره تماس
    editLoginTelephone() {
      this.loginSection = false;
      setTimeout(() => {
        this.signUpSection = true;
      }, 500);
    },
    // دکمه بازگشت در فرم فراموشی رمز عبور
    backToLogin() {
      this.signUpSection = false;
      setTimeout(() => {
        this.loginSection = true;
        setTimeout(() => {
          this.$refs.passWord.select();
        });
        this.textModal = "ورود به وبسایت";
      }, 500);
    },
    // این تابع برای کلیک بروی ورودی ها است
    selectInput(e) {
      e.target.select();
    },

    // حرکت در بین اینپوت ها
    changeInput(event) {
      if (event.target.value.length === 5) {
        for (let i = 0; i <= 4; i++) {
          this.codeInput[i] = event.target.value[i]
        }
        this.verify();
        document.getElementById("loginModal").focus();
        return
      }
      console.log(event);
      let stringId = event.target.id;
      let numberId = parseInt(stringId[stringId.length - 1]);
      if (event.key == "Backspace") {
        document.getElementById("codeInput" + (numberId - 1)).select();
      } else {
        if (numberId == 5) {
          this.verify();
          document.getElementById("loginModal").focus();
        } else {
          document.getElementById("codeInput" + (numberId + 1)).select();
        }
      }
    },
    // تابع تغییر رمز عبور کاربر
    sendNewPassword() {
      // اعتبار سنجی ورودی ها
      if (this.newPassword == null || this.newPassword.length < 4) {
        return (this.forgetError = "رمز عبور می بایست از 4 کارکتر بیشتر باشد");
      } else if (this.newRePassword != this.newPassword) {
        return (this.forgetError = "تکرار رمز عبور اشتباه است");
      }
      this.forgetDisabled = true;
      this.forgetError = "";
      // ساخت فرم دیتا برای ارسال
      const formdata = new FormData();
      formdata.append("mobile", this.telephone);
      formdata.append("password", this.newPassword);
      formdata.append("password_confirmation", this.newRePassword);
      formdata.append("_method", "put");
      formdata.append("customer_id",this.customer_id)
      formdata.append("sms_token", this.$root.getCookie("Token"));
      this.$axios
        .post("/user/password/reset", formdata, {
          params: cart.getCartRequestQueryString(),
        })
        .then((response) => {
          // بعد از دریافت پاسخ ست کردن کوکی
          this.$store.commit(
            "front/setToken",
            response.data.data.data.access_token
          );

          this.$store.commit("front/setLoginStatus", response.data.data.data);
          // غیرفعال کردن دکمه
          this.forgetDisabled = false;
          // خروج از مودال
          document.getElementById("btnCloseLoginModal").click();
          window.swal({
            title: "خوش آمدید",
            text: "رمز عبور شما با موفقیت تغییر کرد",
            icon: "success",
            timer: 1500,
          });
        })
        .catch((error) => {
          // در صورت دریافت خطا نمایش به کاربر
          if (error.statusCode === 422) {
            this.forgetError = error;
          }
        });
    },
    closeModal() {
      // برگرداندن به وضعیت اولیه با بستن مودال
      setTimeout(() => {
        this.loginSection = false;
        this.TokenSection = false;
        this.signUpSection = true;
        this.signUpSectionPart1 = true;
        this.signUpSectionPart2 = false;
        this.signUpSectionPart3 = false;
        this.verfiyError = "";
        this.forgetSection = false;
        this.disabledVerify = false;
        this.disabled1 = false;
        this.disabled = false;
        this.forgetDisabled = false;
        this.signUpDisabled = false;
        this.textModal = "ورود به وبسایت";
        this.loginError = "";
        this.forgetError = "";
        this.SignUpError = "";
        this.tokenName = "";
        this.telephone = "";
        this.password = null;
        this.loginPassword = null;
        this.showAlert = false;
        this.forgetError = "";
        // دادن زمان برای انجام فرآیند های زیر تا در نمایش به کاربر زیبا تر شود
      }, 1000);
    },

    signUp() {
      // شروع مراحل عضو کردن
      this.loginSection = false;

      setTimeout(() => {
        // نمایش بخش عضویت

        this.signUpSection = true;
        this.textModal = "عضویت";
      }, 500);
      // مقدار  توکن نی م که مشخص میکند از فرم ورود شماره برای چه استفاده شده است
      this.tokenName = "register";
    },
    // بعد از فشردن دکمه فراموشی رمز عبور تابع زیر صدا زده میشود تا بخش فراموشی رمز عبور نمایش داده شود
    forgetPassword() {
      // this.loginSection = false;
      // setTimeout(() => {
      //   this.textModal = "فراموشی رمز عبور";
      //   this.signUpSection = true;
      // }, 500);
      // بخش لاگین غیرفعال و بخش اولیه عضویت برای استفاده از فرمش فعال میشود
      this.loginSection = false;
      setTimeout(() => {
        this.signUpSectionPart2 = true;
        setTimeout(() => {
          this.$refs.codeInput1.focus();
        });

        this.signUpSectionPart1 = false;
        this.signUpSection = true;
      }, 500);
      // مقدار  توکن نی م که مشخص میکند از فرم ورود شماره برای چه استفاده شده است
      // this.tokenName = "login";
      this.tokenName = "forget";

      this.sendCodeToCustomer();
      // مقدار  توکن نی م که مشخص میکند از فرم ورود شماره برای چه استفاده شده است
      this.tokenName = "forget";
    },
    // این تابع توکن را از سمت سرور برای مشتری میفرستد تا در بخش های مختلف از آن استفاده شود
    sendCodeToCustomer() {
      this.mobile = this.telephone;
      // تابع اولیه برای اهراز موبایل و ارسال کد پنج رقمی
      if (this.telephone == null || this.telephone.length != 11) {
        this.showAlert = true;
      } else {
        this.showAlert = false;
        this.disabled = true;
        const formData = new FormData();
        formData.append("mobile", this.telephone);
        formData.append("customer_id",this.customer_id)

        // چه شماره همراه درست بود چه غلط پنجره ورودکد نمایش داده شود تا اگر شماره اشتباهی وارد کرده تصحیح کنه
        if (this.tokenName == "forget" || this.tokenName == "login") {
          this.$axios.post("/user/send/token", formData).then((res) => {
            this.disabled = false;

            // برای نمایش قسمت گرفتن توکن
            // استفاده از تایم اوت برای نمایش درست کلاس های فید
            this.signUpSectionPart1 = false;
            // تعمیر استایل
            setTimeout(() => {
              this.signUpSectionPart2 = true;
              setTimeout(() => {
                this.$refs.codeInput1.focus();
              });
            }, 500);
          });
        } else {
          this.$axios
            .post("/user/register-login", formData)
            .then((res) => {
              this.disabled = false;

              this.showAlert = false;
              if (res.data.data.status == "login") {
                this.signUpSection = false;
                setTimeout(() => {
                  this.loginSection = true;
                  setTimeout(() => {
                    this.$refs.passWord.select();
                  });
                }, 500);
              } else {
                this.signUpSectionPart1 = false;
                setTimeout(() => {
                  this.signUpSectionPart2 = true;

                  setTimeout(() => {
                    this.$refs.codeInput1.focus();
                  });
                }, 500);
              }
              // برای نمایش قسمت گرفتن توکن
              // استفاده از تایم اوت برای نمایش درست کلاس های فید
            })
            .catch((error) => {
              this.showAlert = true;

              this.disabled = false;
            });
        }
      }
    },
    // گر شماره تلفن را وارد کرد و وارد بخش ارسال توکن به سمت سرور برای اهراز هویت شد و شماره را اشتباه وارد کرده بود با فشردن دکمه ویرایش شماره این تابع صدا زده میشود

    editTelephone() {
      // خالی کردن ورودی
      while (this.codeInput.length > 0) {
        this.codeInput.pop();
      }
      // بازگشت به فرم برای ویرایش شماره همراه
      this.signUpSectionPart2 = false;
      setTimeout(() => {
        this.signUpSectionPart1 = true;

        // فعال کردن دکمه
        this.disabled = false;
      }, 500);
    },
    // بعد از نمایش فرم تکمیل اطلاعات برای ارسال اطلاعات به سمت سرور این تابع صدا زده میشود
    signUpCustomer() {
      // اعتبار سنجی اولیه
      // اگر فرم به صورت کامل بود
      if (this.showFullForm) {
        if (this.first_name == null || this.first_name.length < 1) {
          this.firstNameError = true;
          return window
            .swal({
              title: "",
              icon: "warning",
              showConfirmButton: true,
              text: "کاربر عزیز وارد کردن نامی با بیش از دو کارکتر الزامی است",
              button: "متوجه شدم",
            })
            .then(() => {
              this.$refs.firstName.scrollIntoView({
                behavior: "auto",
                block: "center",
                inline: "center",
              });
              this.$refs.firstName.focus();
            });
        } else if (this.last_name == null || this.last_name.length < 3) {
          this.firstNameError = false;
          this.lastNameError = true;
          return window
            .swal({
              title: "",
              icon: "warning",
              showConfirmButton: true,
              text: "کاربر عزیز وارد کردن نامی با بیش از سه کارکتر الزامی است",
              button: "متوجه شدم",
            })
            .then(() => {
              this.$refs.lastName.scrollIntoView({
                behavior: "auto",
                block: "center",
                inline: "center",
              });
              this.$refs.lastName.focus();
            });
        }
        this.firstNameError = false;
        this.lastNameError = false;
        if (this.email != null && this.email.length > 5) {
          if (!this.validEmail(email)) {
            this.emailError = true;
            return window
              .swal({
                title: "",
                icon: "warning",
                showConfirmButton: true,
                text: "کاربر عزیز وارد کردن نامی با بیش از سه کارکتر الزامی است",
                button: "متوجه شدم",
              })
              .then(() => {
                this.$refs.email.scrollIntoView({
                  behavior: "auto",
                  block: "center",
                  inline: "center",
                });
                this.$refs.email.focus();
              });
          } else {
            this.emailError = false;
          }
        }
        if (this.password == null || this.password.length < 5) {
          this.passwordError = true;
          return window
            .swal({
              title: "",
              icon: "warning",
              showConfirmButton: true,
              text: "کاربر عزیز وارد کردن رمز عبوری با بیش از 5 کارکتر الزامی است",
              button: "متوجه شدم",
            })
            .then(() => {
              this.$refs.lastName.scrollIntoView({
                behavior: "auto",
                block: "center",
                inline: "center",
              });
              this.$refs.lastName.focus();
            });
        }
      } else {
        // اگر فرم فقط پسورد را میخواست
        if (this.password == null) {
          this.signUpDisabled = false;
          return (this.SignUpError = "لطفا یک رمز عبور انتخاب کنید");
        } else if (this.password.length < 4) {
          this.signUpDisabled = false;
          return (this.SignUpError = "رمزعبور باید حداقل 6 رقم باشد");
        }
      }
      // ساخت فرم دیتا از اطاعات وارد شده
      const formdata = new FormData();

      formdata.append("mobile", this.telephone);
      formdata.append("password", this.password);
      formdata.append("customer_id",this.customer_id)
      if (this.showFullForm) {
        formdata.append("first_name", this.first_name);
        formdata.append("last_name", this.last_name);
        formdata.append("gender", this.gender);
        

        if (this.brithDay != null) {
          formdata.append("gender", this.brithDay);
        }
        if (this.email.length > 6) {
          formdata.append("email", this.email);
        }
      }
      formdata.append("sms_token", this.$root.getCookie("dailyAuthorization"));
      // ارسال اطلاعات به سمت سرور
      this.signUpDisabled = true;
      this.$axios
        .post("/user/register", formdata, {
          params: cart.getCartRequestQueryString(),
        })
        .then((response) => {
          this.signUpDisabled = false;

          // بعد از دریافت پاسخ درست ست کردن کوکی و بعد خروج از مدال
          this.$store.commit("front/setToken", response.data.data.data.access_token);
          this.$store.commit("front/setLoginStatus", response.data.data.data);

          document.getElementById("btnCloseLoginModal").click();
        }).catch(error => {
        this.signUpDisabled = false;
        this.$root.notify(error)
      });
    },
    // اعتبار سنجی ایمیل
    validEmail: function (email) {
      var re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
    },
    // این تابع برای اهراز هویت توکن ارسالی از سمت مشتری است
    verify() {
      this.mobile = this.telephone;
      const formdata = new FormData();
      formdata.append("mobile", this.telephone);
      formdata.append("customer_id",this.customer_id)

      // چک برای اینکه کد فعالسازی برای ورود است یا عضویت یا فراموشی رمز عبور
      if (this.tokenName == "login") {
        formdata.append("type", "login");
      } else if (this.tokenName == "register") {
        formdata.append("type", "register");
      } else {
        formdata.append("type", "forget");
      }
      this.disabledVerify = true;
      // استفاده از تابع جوین برای متصل کردن اینپوت ها به هم
      let token = this.codeInput.join("");
      formdata.append("sms_token", token);
      this.$axios
        .post("/user/verify", formdata, {
          params: cart.getCartRequestQueryString(),
        })
        .then((response) => {
          this.codeInput = [];

          this.disabledVerify = false;
          this.verfiyError = "";
          // اگر برای لاگین بود توکن ست شود
          if (this.tokenName == "login") {
            this.$store.commit(
              "front/setToken",
              response.data.data.data.access_token
            );

            this.$store.commit("front/setLoginStatus", response.data.data.data);

            document.getElementById("btnCloseLoginModal").click();
            // اگر برای فراموشی بود فرم رمز جدید نمایش داده شود
          } else if (this.tokenName == "forget") {
            // اگر برای عضویت بود برویم سراغ پارت دوم و ست کردن توکن روزانه
            this.signUpSection = false;
            setTimeout(() => {
              this.forgetSection = true;
            }, 500);
            this.$root.setCookie("Token", token);
          } else {
            this.signUpSectionPart2 = false;
            setTimeout(() => {
              this.signUpSectionPart3 = true;
              this.$nextTick(() => {
                document.getElementById('password').select()
              })
              // inja
            }, 500);
            this.$root.setCookie("dailyAuthorization", token);
          }
        })
        .catch((error) => {
          this.disabledVerify = false;
          this.verfiyError = "کد وارد شده صحیح نمی باشد";
        });
    },
    // با فشردن دکمه ورود با رمز یکبار مصرف داریم
    loginWithToken() {
      // بخش لاگین غیرفعال و بخش اولیه عضویت برای استفاده از فرمش فعال میشود
      this.loginSection = false;
      setTimeout(() => {
        this.signUpSectionPart2 = true;
        setTimeout(() => {
          this.$refs.codeInput1.focus();
        });

        this.signUpSectionPart1 = false;
        this.signUpSection = true;
      }, 500);
      // مقدار  توکن نی م که مشخص میکند از فرم ورود شماره برای چه استفاده شده است
      this.tokenName = "login";
      this.sendCodeToCustomer();
    },
    // ورود مستقیم با رمز عبور با این تابع صورت میگیرد
    login() {
      if (this.loginPassword == null || this.loginPassword.length < 4) {
        return (this.loginError = "رمز عبور شما از چهار کارکتر بیشتر بوده است");
      }
      this.disabled1 = true;
      this.loginError = "";
      const formdata = new FormData();
      formdata.append("mobile", this.mobile);
      formdata.append("password", this.loginPassword);
      formdata.append("customer_id",this.customer_id)

      if (this.$root.pushToken) {
        formdata.append("device_token", this.$root.pushToken);
      }
      // ساخت فرم دیتا و ارسال به سمت سرور
      this.$axios
        .post("/user/login", formdata, {
          params: cart.getCartRequestQueryString(),
        })
        .then((response) => {
          const data = response.data.data.data;
          // بعد از دریافت پاسخ ست کردن کوکی و خروج از مودال
          this.$store.commit(
            "front/setToken",
            response.data.data.data.access_token
          );

          console.log(data);
          this.$store.commit("front/setLoginStatus", data);
          this.disabled1 = false;

          document.getElementById("btnCloseLoginModal").click();

          if(this.$route.query.loginFromOrder){
            this.$router.push(`order/${this.$route.query.loginFromOrder}`)
          }
          if(this.$route.query.loginFromCart){
              this.$router.push('/cart')
          }
        })
        .catch((error) => {
          console.error(error);
          this.disabled1 = false;
          this.loginError = "شماره موبایل یا رمز عبور شما اشتباه بوده است";
        });
    },
  },
  watch: {
    textModal(val) {
      this.$emit("changeTextModal", val);
    },
    value(val) {
      this.$emit("input", val);
    },
    showPass(newVal) {
      if (newVal) {
        document.getElementById("passWord").type = "text";
      } else {
        document.getElementById("passWord").type = "password";
      }
    },
  },
  computed: {
    customer_id(){
      return this.$store.getters["front/getCustomer_id"];
    },
    //  استفاده از این متد برای نمایش بهتر مدال و فرم ها
    modalHeight() {
      if (this.loginSection) {
        this.value = "350px !important";
      } else if (this.forgetSection) {
        this.value = "430px !important";
      } else {
        if (this.signUpSectionPart1) {
          this.value = "330px !important";
        }
        if (this.signUpSectionPart2) {
          this.value = "380px !important";
        }
        if (this.signUpSectionPart3) {
          this.value = "300px !important";
        }
        this.value = "300px !important";
      }
    },
  },
  mounted() {
    let RespLoginModal = this;
    $("#loginModal").on("hidden.bs.modal", function () {
      RespLoginModal.closeModal();
    });
  },
};
</script>

<style scoped>
/* افزودن استایل به کدهای اضافه شده به قالب */
#mobile {
  width: 95%;
  background: white;
  height: 30px;
  border-radius: 0;
}

.btnLogin{
  padding: 10px !important;
  border-radius: 10px;
}
#signupCheckbox {
  display: flex;
  justify-content: space-around;
}
#signupCheckbox div input {
  position: relative;
  top: 4px;
  left: 5px;
}
#codeInput {
  direction: ltr;
}
#otherUserOption {
  display: flex;
  width: 80%;
  justify-content: space-between;
  margin: auto;
  margin-top: 10px;
}
#otherUserOption p {
  cursor: pointer;
  transition: "text-shadow" 1s;
}
#otherUserOption p:hover {
  text-shadow: -1px 1px 7px blue;
}
.loginInputs {
  width: 95%;
  padding-right: 5px;
  line-height: 36px;
}
#closeModal2 {
  background-color: silver;
}
#continueSignUpSection {
  display: flex;
  justify-content: center;
  margin-bottom: 15px;
}
#continueSignUpSection input {
  /*border-radius: 5px;*/
  margin-left: 10px;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
#loginHeader {
  display: flex;
  justify-content: space-around;
  margin-bottom: 15px;
}
#loginWithTokenLink {
  cursor: pointer;
  transition: "text-shadow" 1s;
}
#loginWithTokenLink:hover {
  text-shadow: -1px 1px 7px blue;
}
/* اصلاح قالب
 */
/* درست کردن قالب ورود کد */
.login-modal .modal-body input[type="tel"], .login-modal .modal-body input[type="number"] {
  background-color: #dddddd;
  /*border-radius: 10px;*/
  width: 60px;
  height: 60px;
}

@media screen and (max-width: 390px) {
  .login-modal .modal-body input[type="tel"], .login-modal .modal-body input[type="number"] {
    width: 50px;
    height: 50px;
  }
}

/* برداشتن دکمه های بالا پایین برای ورود عدد */
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

#showSignUpResult {
  text-align: center;
  display: flex;
  flex-direction: column;
}
#showSignUpResult i {
  color: green;
  font-size: 50px;
}
.login-modal .modal-content {
  height: max-content !important;
}
#mobileInput {
  background-color: var(--color-input);
  padding: 10px !important;
  border-radius: 10px;
  /*border-radius: 10px;*/
}
#mobileInput::placeholder{
  font-size: 16px;
}
#modal-content {
  transition: all 0.3s;
  height: 300px;
  background: #F7E9E3;
}

.modal-content {
  min-height: 340px;
}
/* نمایش تمام صفحه مودال در حالت موبایلی
 */
@media (max-width: 768px) {
  #modal-content {
    height: 100vh !important;
    border-radius: 0 !important;
    width: 100% !important;
    position: fixed;
    top: 0;
  }
  .modal-dialog {
    margin: 0;
  }
}
#hrInput {
  margin-top: 0;
}
#modal-content {
  transition: all 1s;
}
#loginErrorBox {
  margin-top: 5px;
}
#backBtn {
  background: transparent;
  font-size: larger;
}
.numberWar {
  margin: 2px;
  padding: 10px;
  text-align: center;
}
#btnCloseLoginModal {
  background-color: transparent;
}

.inputError {
  background-color: #f5eae7;
  border: 1px solid pink;
}
#chooseGenderAndImageBox {
  margin-bottom: 0 !important;
}
/* full from */
#registerForm {
  display: flex;
  flex-wrap: wrap;
}
.marginAuto {
  margin: auto;
}
.codeInputFullForm input[type="number"] {
  width: 40px;
  font-size: 22px;
  /*border-radius: 5px;*/
  background-color: #e5e5e5;
}
.verifyFullFrom {
  width: 30%;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}
.paswordInputFullForm {
  width: 80%;
  margin: auto;
}
.forget-Full-Form {
  width: 60%;
  margin: auto;
}
.signUpFullForm {
  width: 60%;
  margin: auto;
  display: flex;
  justify-content: center;
  font-size: larger;
}
/* media */
@media (max-width: 768px) {
  .codeInputFullForm input[type="tel"] {
    width: 40px;
    font-size: 22px;
    /*border-radius: 5px;*/
    background-color: #e5e5e5;
  }
  .forget-Full-Form {
    width: 90%;
    margin: auto;
  }
}
</style>
